import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "การสร้างรายได้จากการเล่น"
    }}>{`การสร้างรายได้จากการเล่น`}</h1>
    <hr></hr>
    <p>{`Play to Earn เพิ่งถูกจัดเข้าไปในหมวดหมู่ของประเภทแนวเกม ซึ่งเกมแนวนี้ส่วนมากจะเชื้อเชิญให้ผู้เล่นเข้าไปลงทุนกับเกม และจ่ายรางวัลให้กับผู้เล่นที่เข้ามาลงทุน
จนมีบางคนบอกว่าเกมแนวนี้มันคือแชร์ลูกโซ่ ซึ่งเราก็ไม่ขอออกความเห็นในส่วนนี้ แต่สิ่งที่เราทำ ไม่ใช่เกมแนว Play to Earn แต่เป็นแนว Play and Earn`}</p>
    <p>{`Play and Earn เราไม่แน่ใจว่าคำนี้ถูกใช้ในที่ไหนบ้าง และมีความหมายอย่างไร แต่สำหรับเรา Play and Earn คือ เกมที่มุ่งเน้นไปที่ความสนุกและเพลิดเพลินในการเล่น เป็นเหมือนเกมปกติทั่วไปที่ทุกคนรู้จัก และสามารถสร้างรายได้จากการเล่น ไม่ใช่ผลตอบแทนในการลงทุน`}</p>
    <blockquote>
      <p parentName="blockquote">{`Play to Earn อาจจะเป็นแอปพลิเคชั่นในการเงินการลงทุนที่ครอบด้วยเกม แต่ Play and Earn นั้น จะเป็นเกมที่เล่นสนุกและสร้างรายได้`}</p>
    </blockquote>
    <hr></hr>
    <h2 {...{
      "id": "รูปแบบการสร้างรายได้-และการใช้จ่าย"
    }}>{`รูปแบบการสร้างรายได้ และการใช้จ่าย`}</h2>
    <p>{`การใช้จ่ายและการได้รับเหรียญ CLC ภายในเกม`}</p>
    <h3 {...{
      "id": "ได้รับ-clc-จาก-"
    }}>{`ได้รับ CLC จาก :`}</h3>
    <ul>
      <li parentName="ul">{`ได้รับรางวัลจากเควส`}</li>
      <li parentName="ul">{`ขายผลผลิตพิเศษที่แผงตลาด`}</li>
      <li parentName="ul">{`ขายตัวละครบนตลาดซื้อขาย`}</li>
    </ul>
    <h3 {...{
      "id": "การใช้จ่าย-clc-"
    }}>{`การใช้จ่าย CLC :`}</h3>
    <ul>
      <li parentName="ul">{`ซื้อกล่องสุ่มตัวละคร`}</li>
      <li parentName="ul">{`ซื้อตัวละครบนตลาดซื้อขาย`}</li>
      <li parentName="ul">{`ซื้อผืนฟาร์ม`}</li>
      <li parentName="ul">{`ค่าธรรมเนียมต่าง ๆ`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "การฝากและการถอนเหรียญ-clc"
    }}>{`การฝากและการถอนเหรียญ CLC`}</h2>
    <p><img alt="wallet" src={require("./public/images/deposit-withdraw.png")} /></p>
    <h3 {...{
      "id": "การฝาก"
    }}>{`การฝาก`}</h3>
    <p>{`คุณสามารถฝากเหรียญ CLC เข้าไปในตัวเกมได้ เพื่อการใช้จ่ายค่า Fee ต่าง ๆ ในกรณีที่ไม่เหลือ CLC ในเกมเลย โดยจะมีเพดานในการฝากดังนี้`}</p>
    <ul>
      <li parentName="ul">{`ฝากขั้นต่ำ ครั้งละ 200 CLC`}</li>
      <li parentName="ul">{`ฝากสูงสุด ครั้งละ 2500 CLC`}</li>
    </ul>
    <h3 {...{
      "id": "การถอน"
    }}>{`การถอน`}</h3>
    <p>{`คุณสามารถถอนเหรียญ CLC ออกจากเกมเข้าสู่กระเป๋าของคุณได้ โดยมีเงื่อนไขในการถอนดังนี้`}</p>
    <ul>
      <li parentName="ul">{`ถอนขั้นต่ำ ครั้งละ 200 CLC `}</li>
      <li parentName="ul">{`ถอนสูงสุด ครั้งละ 2500 CLC`}</li>
    </ul>
    <p>{`หลังจากการถอนครั้งล่าสุด จะมี Cooldown ในการถอนครั้งถัดไป โดยจะสุ่มเป็นระยะเวลา 20 - 24 ชั่วโมง`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      